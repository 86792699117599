<template>
  <b-card>
    <b-card-text>
      <b-row class="w-75 mx-auto">
        <b-col
          cols="12"
        >
          <b-form-group
            label-size="lg"
            label="Destino"
            label-for="destination"
          >
            <b-form-input
              id="email"
              v-model="formData.destination"
              trim
              placeholder=""
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label-size="lg"
            label="Situação"
            label-for="status"
          >
            <b-form-select
              id="status"
              v-model="formData.status"
              :state="formData.status === null ? false : true"
              :options="status_options"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                >Por favor, selecione uma situação!</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            label-size="lg"
            label="Data de solicitação"
            label-for="request_date"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="Trash2Icon"
                  class="feather-icon cursor-pointer"
                  size="18"
                  @click.stop="formData.request_date = null"
                />
              </b-input-group-prepend>
              <DateTimePicker
                v-model="formData.request_date"
                :config="config"
                locale="pt"
                alt-timezone="America/Sao_Paulo"
                date-timezone="UTC"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="mt-2"
        >
          <b-form-group
            label-size="lg"
            label="Comentários/Observações:"
            label-for="status"
          >
            <b-form-textarea
              id="textarea-default"
              v-model="formData.comments"
              placeholder="Comentários da sua conta e observações."
              rows="5"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            label-size="lg"
            label="Valor"
            label-for="number"
          >
            <cleave
              id="number"
              v-model="formData.value"
              class="form-control"
              :raw="true"
              :options="options.number"
              placeholder="Valor"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label-size="lg"
            label="Plataforma"
            label-for="platform"
          >
            <b-form-select
              id="platform"
              v-model="formData.platform"
              :state="formData.platform === null ? false : true"
              :options="platform_options"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                >Por favor, selecione uma situação!</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="d-flex justify-content-center"
        >
          <b-button
            :disabled="loading"
            variant="relief-success"
            @click.stop="create()"
          >
            <span v-if="!loading">Salvar</span>
            <b-spinner
              v-else
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BSpinner, BCard, BCardTitle, BCardText, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BButton, BInputGroupPrepend, BFormSelect, BFormSelectOption, BFormTextarea
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import moment from 'moment'
import DateTimePicker from '@/components/DateTimePicker.vue'
import checkSubscription from '../checkSubscription'

export default {
  components: {
    BSpinner,
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BInputGroupPrepend,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    DateTimePicker,
    vSelect,
    Cleave
  },
  mixins: [checkSubscription],
  data: () => ({
    loading: false,
    formData: {
      destination: '',
      value: 0,
      status: 1,
      request_date: moment().utcOffset('-000').format('Y-MM-DD HH:mm:ss'),
      comments: '',
      platform: null
    },
    status_options: [
      {
        text: 'Pendente',
        value: 1
      },
      {
        text: 'Recusado',
        value: 2
      },
      {
        text: 'Aprovado',
        value: 3
      }
    ],
    platform_options: [
      {
        text: 'Siga Social',
        value: 'Siga Social'
      },
      {
        text: 'Dizu',
        value: 'Dizu'
      },
      {
        text: 'GNI',
        value: 'GNI'
      },
      {
        text: 'Kzom',
        value: 'Kzom'
      },
      {
        text: 'Outros',
        value: 'Outros'
      }
    ],
    options: {
      number: {
        prefix: 'R$ ',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 9,
        numeralDecimalScale: 2
      }
    },
    config: {
      enableTime: true,
      altInput: true,
      altFormat: 'd/m/Y H:i',
      dateFormat: 'Y-m-d H:i'
    }
  }),
  methods: {
    async create () {
      this.loading = true
      const url = '/user/controle_infinity/manage_earns'
      const params = {
        destination: this.formData.destination,
        value: this.formData.value,
        status: this.formData.status,
        request_date: this.formData.request_date,
        comments: this.formData.comments,
        platform: this.formData.platform
      }

      await this.$http.post(url, params)
        .then(() => {
          this.$swal({
            type: 'success',
            title: 'Adicionado com sucesso!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })
        }).catch(() => {
          this.$swal({
            type: 'error',
            title: 'Erro ao adicionar!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            }
          })
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
